canvas {
  height: 100vh;
  pointer-events: none;
  position: fixed;

  width: 100%;
  z-index: 2;
}

button.ready {
  position: absolute;
  height: 30px;
  width: 25px;
  bottom: 36px;
  left: 30px;

  padding: 0;
  background: none;

  border: none;

  cursor: pointer;

  outline: none;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
}

.magic_icon {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: block;
}

.magic_icon path {
  fill: #000;
  transition: fill 500ms ease;
}
