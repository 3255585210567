:root {
  --black-color: #000;
  --white-color: #fff;
  --gray-color: #bab9b9;
  --section-border-color: rgba(186, 185, 185, 0.49);
  --section-1-color: #95bc00;
  --section-2-color: #f34e4e;
  --section-3-color: #ffd745;
  --section-4-color: #008fc0;
  --cubic-animation: cubic-bezier(0.52, 0.19, 0.14, 0.96);
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.section {
  display: flex;
  justify-content: center;
  padding-left: 80px;
  padding-right: 65px;

  position: relative;
  min-width: 320px;
  width: 100%;
  min-height: 25vh;
  overflow: hidden;

  text-align: center;
  color: var(--black-color);
  border: 1px solid var(--section-border-color);
  background-position: -500px 500px;

  transition: background 0.6s var(--cubic-animation);
}

.section:hover {
  min-height: 50vh;
  height: 100%;
}

.container_text {
  color: var(--gray-color);
}

.section:hover .section_item {
  color: var(--white-color);
}

.section:hover .container_text,
.section:hover .container_title--number {
  color: var(--white-color);
}

.section:hover .container_text {
  display: block;
}

.section:hover .container_text--line::after,
.section:hover .icon_box,
.section:hover .site--line::after {
  background-color: var(--white-color);
}

.section:hover .icon_box {
  transform: rotatez(-90deg);
}

.section:nth-child(1) > #button {
  display: none;
}

.section:nth-child(1):hover {
  color: var(--white-color);
  background-color: var(--section-1-color);
}

.section:nth-child(1):hover .logo_icon g,
.section:nth-child(1):hover .magic_icon path {
  fill: var(--white-color);
}

.section:nth-child(1):hover .arrow_icon line {
  stroke: var(--section-1-color);
}

.section:nth-child(1):hover .btn-lang {
  color: var(--white-color);
}

.section:nth-child(1):hover .Message path {
  fill: var(--white-color);
}

.section:nth-child(2):hover {
  color: var(--white-color);
  background-color: var(--section-2-color);
}

.section:nth-child(2):hover .arrow_icon line {
  stroke: var(--section-2-color);
}

.section:nth-child(3):hover {
  color: var(--white-color);
  background-color: var(--section-3-color);
}

.section:nth-child(3):hover .arrow_icon line {
  stroke: var(--section-3-color);
}

.section:nth-child(4):hover {
  color: var(--white-color);
  background-color: var(--section-4-color);
}

.section:nth-child(4):hover .feedback-message,
.section:nth-child(4):hover .btn-lang {
  color: var(--white-color);
}

.section:nth-child(4):hover .arrow_icon line {
  stroke: var(--section-4-color);
}

.section:nth-child(4):hover .Message path,
.section:nth-child(4):hover .magic_icon path {
  fill: var(--white-color);
}

.section_item {
  position: relative;
  width: 100%;
  margin: auto;
  transition: transform 0.3s var(--cubic-animation) 0.3s;
  color: var(--black-color);
}

.section:nth-child(1) .section_item {
  margin-top: 90px;
}

.container_title,
.container_text,
.container_title--number {
  transition: transform 0.3s var(--cubic-animation) 0.3s;
}

.container_title {
  text-align: left;
  font-family: 'Bicubik';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  color: inherit;
}

.container_title--number {
  position: absolute;
  top: -2px;
  left: -55px;
  font-family: 'Swiss-721';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 1.19;
  color: var(--gray-color);
}

.container_site {
  position: absolute;
  /* right: -70px; */
  right: -45px;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  height: 170px;
}

.site {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 20px; */
  margin-right: auto;
  margin-left: auto;
  max-width: 184px;
  text-decoration: none;
}

.site--line {
  color: inherit;
}

.site--line::after {
  content: '';
  display: flex;
  margin-top: 1px;
  height: 1px;
  width: 100%;
  background-color: var(--black-color);
}

.site:visited {
  color: var(--black-color);
}

.container_text {
  margin: auto;
  margin-top: 16px;

  font-family: 'Jura';
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 1.23;
  text-align: left;

  display: none;
}

.container_text--line::after {
  content: '';
  display: flex;
  margin-top: 23px;
  height: 0;
  width: 100%;
  background-color: var(--black-color);
}

.feedback-message {
  position: absolute;
  bottom: 36px;
  right: 30px;

  display: flex;
  align-items: center;

  color: var(--black-color);
  font-family: 'Jura';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.17;
  text-align: center;
  text-transform: uppercase;

  transition: color 0.3s var(--cubic-animation);
}

/* --------------start--------- выпадающее меню языка */
.language_in_1_section {
  position: absolute;

  top: 20px;
  right: 20px;

  display: flex;

  align-items: center;

  font-family: 'Jura';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.17;
  text-align: center;
  text-transform: uppercase;

  transition: color 0.3s var(--cubic-animation);
}

.language_in_4_section {
  position: absolute;
  top: 36px;
  right: 30px;

  /* display: flex; */
  display: none;
  align-items: center;

  font-family: 'Jura';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.17;
  text-align: center;
  text-transform: uppercase;

  transition: color 0.3s var(--cubic-animation);
}

.Message {
  margin-left: 5px;
  transition: transform 0.3s var(--cubic-animation);
}

.hidden-menu {
  display: block;
  padding: 0;
  margin: 0;

  position: absolute;
  top: -200px;
  z-index: 2;

  box-sizing: border-box;
  list-style: none;

  transition: top 0.3s;
}

.menu-item {
  font-family: 'Jura';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.17;
  text-align: center;
  text-transform: uppercase;
}

.menu-item:not(:first-child) {
  margin-top: 10px;
}

.btn-lang {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  color: var(--black-color);

  transition: color 0.3s var(--cubic-animation);
}

.hidden-menu-ticker {
  display: none;
}

.btn-menu {
  display: flex;
  align-items: center;

  cursor: pointer;
  transition: top 0.23s;
  z-index: 3;
}

.item-ref {
  color: var(--black-color);
  text-decoration: none;

  transition: color 0.3s var(--cubic-animation);
}

.hidden-menu-ticker:checked ~ .btn-menu {
  top: 100px;
}
.hidden-menu-ticker:checked ~ .hidden-menu {
  color: var(--white-color);
  top: 20px;
}
.hidden-menu-ticker:checked ~ .btn-menu > .Message {
  transform: rotate(180deg);
}

/* -------------------end-------------- выпадающее меню языка */

.logo_icon {
  position: absolute;
  left: 18px;
  top: 19px;

  display: block;
  width: 56px;
  height: 48px;

  z-index: 2;
}

.logo_icon g,
.magic_icon path,
.Message path {
  fill: var(--black-color);
  transition: fill 500ms ease;
}

.icon_box {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 47px;
  height: 47px;

  background-color: var(--gray-color);
  border-radius: 50%;
  transition: transform 0.3s var(--cubic-animation);
}

.site:hover .icon_box {
  transform: rotate(45deg);
}

.arrow_icon line {
  fill: var(--white-color);
}

/* -------------------------------------- адаптив на странице */

@media (max-width: 769px) {
  /* .section_item {
    width: 79vw;
  } */

  /* .section:hover {
    height: 35vh;
  } */
}

@media (min-width: 1024px) {
  .container {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .section {
    height: 100vh;
  }

  .section:hover {
    height: 100vh;
  }

  .section:hover .section_item {
    transform: translateY(20vh);
  }

  .section:hover .icon_box {
    transform: rotatez(0deg);
  }
  .container_site:hover .icon_box {
    transform: rotatez(45deg);
  }

  .section:nth-child(1):hover {
    background-image: url('./data/Frame1.svg');
    background-size: 320px;
    background-position: 0px 148px;
    background-repeat: no-repeat;
  }
  .section:nth-child(2):hover {
    background-image: url('./data/Frame2_1.svg'), url('./data/Frame2_2.svg'),
      url('./data/Frame2_3.svg');
    background-size: 256px, 78px, 121px;
    background-position: left -28px top 66px, right 0 top 88px,
      right -11px top 282px;
    background-repeat: no-repeat;
  }
  .section:nth-child(3):hover {
    background-image: url('./data/Frame3_1.svg'), url('./data/Frame3_2.svg'),
      url('./data/Frame3_3.svg'), url('./data/Frame3_4.svg');
    background-size: 37px, 69px, 36px, 271px;
    background-position: left 162px top 42px, right -14px top 126px,
      left 206px top 372px, left -37px top 100px;
    background-repeat: no-repeat;
  }
  .section:nth-child(4):hover {
    background-image: url('./data/Frame4_1.svg'), url('./data/Frame4_2.svg'),
      url('./data/Frame4_3.svg'), url('./data/Frame4_4.svg'),
      url('./data/Frame4_5.svg');
    background-size: 64px, 67px, 89px, 75px, 420px;
    background-position: left 175px top 32px, left 180px top 129px,
      right 0 top 126px, right 17px top 293px, left -108px top 55px;
    background-repeat: no-repeat;
  }
  .section_item {
    min-height: 265px;
    width: 184px;
  }

  .section:nth-child(1) .section_item {
    margin-top: auto;
  }

  .container_site {
    position: static;
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    height: auto;
  }

  .site {
    margin: 0;
  }

  .logo_icon {
    top: 36px;
    left: 23px;
    width: 97px;
    height: 77px;
  }

  .container_text {
    display: block;
  }

  .container_text--line::after {
    height: 1px;
  }

  .section:nth-child(1) > #button {
    display: block;
  }

  .section:nth-child(4) > #button {
    display: none;
  }

  .language_in_1_section {
    display: none;
  }

  .language_in_4_section {
    display: flex;
  }
}
