body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Jura';
  src: local('Jura'), url('./fonts/Jura-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Jura';
  src: local('Jura'), url('./fonts/Jura-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Jura';
  src: local('Jura'), url('./fonts/Jura-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Jura';
  src: local('Jura'), url('./fonts/Jura-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Jura';
  src: local('Jura'), url('./fonts/Jura-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Bicubik';
  src: local('Bicubik'), url('./fonts/Bicubik-regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Swiss-721';
  src: local('Swiss-721'),
    url('./fonts/Swiss-721-Black-Outline-BT.ttf') format('truetype');
  font-weight: 900;
}
