:root {
  --black-color: #000;
  --white-color: #fff;
  --gray-color: #bab9b9;
  --section-border-color: rgba(186, 185, 185, 0.49);
  --section-1-color: #95bc00;
  --section-2-color: #f34e4e;
  --section-3-color: #ffd745;
  --section-4-color: #008fc0;
  --cubic-animation: cubic-bezier(0.52, 0.19, 0.14, 0.96);
}

.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: var(--white-color);
  z-index: 5;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 48px;
  width: 100%;
  overflow: hidden;

  font-family: 'Bicubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 1.23;

  color: var(--black-color);
}

.content__container {
  display: flex;
  justify-content: center;
  font-weight: 600;
  overflow: hidden;
}

.content__container__text {
  display: inline;
  margin: 0;
}

.content__container__list {
  margin: 0;
  padding-left: 110px;
  text-align: left;
  list-style: none;
  padding: 0;
  margin-left: 18px;

  animation-name: change;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.content__container__list li:nth-child(1) {
  color: var(--section-1-color);
}

.content__container__list li:nth-child(2) {
  color: var(--section-2-color);
}

.content__container__list li:nth-child(3) {
  color: var(--section-3-color);
}

.content__container__list li:nth-child(4) {
  color: var(--section-4-color);
}

.content__container__item {
  line-height: 40px;
  margin: 0;
}

@keyframes change {
  0%,
  8.5%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%,
  21% {
    transform: translate3d(0, -25%, 0);
  }
  25%,
  33.5% {
    transform: translate3d(0, -50%, 0);
  }
  37.5%,
  46% {
    transform: translate3d(0, -75%, 0);
  }
  50%,
  58.5% {
    transform: translate3d(0, -100%, 0);
  }
  62.5%,
  71% {
    transform: translate3d(0, -75%, 0);
  }
  75%,
  83.5% {
    transform: translate3d(0, -50%, 0);
  }
  87.5%,
  96% {
    transform: translate3d(0, -25%, 0);
  }
}

@media (max-width: 1023px) {
  .content {
    height: 25px;
    font-size: 25px;
  }

  .content__container {
    font-weight: 600;
    overflow: hidden;
  }

  .content__container__list {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .content {
    height: 13px;

    font-size: 13px;
  }

  .content__container {
    font-weight: 600;
    overflow: hidden;
  }

  .content__container__list {
    margin-left: 4px;
  }
}
